.hotel-detail-container{
    .hotel-container{
        max-width: 1260px;
    }
    .hotel-reviews-box{
        border-bottom: 1px solid lightgray;
        padding-bottom: 40px;
        margin-bottom: 40px;
        .review-stars{
            svg{
                color: #FA5636;
                font-size: 13px;
                margin-right: 5px;
            }
        }
        .reviews-tours{
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: 800px) {
                flex-wrap: wrap;
                row-gap: 20px;
                .review-box{
                    width: 100% !important;
                }
            }
            .review-box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 47%;
                margin: 0;
                // padding-top: 11px;
                .review-number{
                    background: #F9FBFF;
                    border: 1px solid #B8CCFF;
                    padding: 5px 5px;
                    border-radius: 4px;
                    color: #3b71fe;
                    font-size: 15px;
                    font-weight: 700;
                    letter-spacing: 2.3px;
                    line-height: 20px;
                    margin: 0;
                }
                .location{
                    color: #5E6D77;
                    font-size: 15px;
                    margin-left: 10px;
                }
                .review-no{
                    color: #5E6D77;
                    font-size: 15px;
                    margin-left: -13px;
                }
                span{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    margin-left: 23px;
                }
            }

            .share-icons{
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 10px;
                cursor: pointer;
                .share-icon{
                    background: #FFFFFF;
                    border: 1px solid #DEDEDE;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                    border-radius: 40px;
                    width: 44px;
                    height: 44px;
                    display: flex;
                    text-align: center;
                    line-height: 44px;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    svg{
                        font-size: 22px;
                    }
                    .social-share-icons{
                        position: absolute;
                        top: 44px;
                        span{
                            background: #FFFFFF;
                            border: 1px solid #DEDEDE;
                            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                            border-radius: 40px;
                            width: 44px;
                            height: 44px;
                            display: flex;
                            text-align: center;
                            line-height: 44px;
                            justify-content: center;
                            align-items: center;
                            margin-top: 10px;
                            svg{
                                font-size: 16px;
                            }
                        }
                    }
                }
                .like-icons{
                    background: #FFFFFF;
                    border: 1px solid #DEDEDE;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                    border-radius: 40px;
                    width: 44px;
                    height: 44px;
                    display: flex;
                    text-align: center;
                    line-height: 44px;
                    justify-content: center;
                    align-items: center;
                    svg{
                        font-size: 22px;
                    }
                }
            }
        }
        
    }
    .hotel-detail-section{
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            .side-bar-image{
                display: none !important;
            }
            .google-map{
                display: none !important;
            }
            .user-profile-box{
                display: none;
            }
            .hotel-right-section{
                display: none;
                .right-section-stick{
                    position: absolute !important; 
                    top:0;
                    z-index: 99;
                }
            }
        }
        .hotel-right-section{
            margin: 0;
            width: 32%;
            position: relative;
            .right-section-stick{
                position: sticky;
                top: 0;
            }
            .booking-section{
                padding: 30px;
                border: 1px solid #DEDEDE;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                border-radius: 20px;
                width: 100%;
                .booking-tabs-btn{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .primary-btn{
                        padding: 16px 30px;
                        width: 48%;
                        font-weight: 600;
                    }
                    .not-active-btn{
                        background-color: #F7F8FA;
                        color: black;
                        border: 1px solid lightgray;
                        border-radius: 50px;
                        padding: 16px 30px;
                        font-size: 15px;
                        width: 48%;
                        font-weight: 600;
                    }
                }

                .hotel-price{
                    display: flex;
                    align-items: center;
                    margin-top: 19px;
                    justify-content: space-between;
                    div{
                        display: flex;
                        align-items: center;
                        margin: 0;
                        column-gap: 10px;
                    }
                    p{
                        margin: 0;
                        font-size: 15px;
                        color: #5E6D77;
                        span{
                            font-size: 18px;
                            font-weight: 700;
                            color: black;
                        }
                    }
                    svg{
                        color: #FFB21D;
                    }
                }

                .hotel-date-selecter{
                    border: 1px solid #dadada;
                    margin-top: 25px;
                    border-radius: 20px;
                    .DateInput{
                        border-right: 1px solid lightgray;
                        width: 50%;
                        padding: 10px;
                        &:nth-last-child(1){
                            border-right: none;
                        }
                    }
                    .date-picker{
                        border-bottom: 1px solid lightgray;
                    padding: 5px;

                    }
                    .DateRangePickerInput__withBorder{
                        border: none;
                    }
                    .DateRangePickerInput_arrow_svg{
                        display: none;
                    }

                    .guest-bar{
                        position: relative;
                        padding: 27px;
                        @media only screen and (max-width: 800px) {
                            width: 100%;
                            border-left: none;
                            border-top: 1px solid lightgray;
                            justify-content: start;
                            svg{
                                margin: 0;
                            }
                            .guest-text-box{
                                margin: 0;
                                margin-left: 17px;
                                margin-bottom: 14px;
                                padding-top: 13px;
                                cursor: pointer;
                            }
                        }
                        .guest-dropdown{
                            position: absolute;
                            top: 110px;
                            left: 0;
                            background: white;
                            z-index: 9;
                            padding: 19px;
                            border-radius: 12px;
                            box-shadow: 1px 2px 3px 1px lightgray;
                            width: 340px;
                            .counter-box{
                                display: flex;
                                align-items: center;
                                margin-bottom: 20px;
                                border-bottom: 1px solid #e1e1e1;
                                padding-bottom: 14px;
                                justify-content: space-between;
                                p{
                                    margin: 0;
                                }
                                &:nth-last-child(1){
                                    border-bottom: none;
                                }
                                .counter{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 40%;
                                    margin: 0;
                                    p{
                                        color: blue;
                                    }
                                    svg{ 
                                        border: 1px solid lightgray;
                                        border-radius: 40px;
                                        font-size: 25px;
                                        width: 28px;
                                        height: 28px;
                                    }
                                }
                            }
                        }
                    }

                   
                }
                .availabilty-check{
                    width: 100%;
                    margin-top: 25px;
                    .primary-btn{
                        width: 100%;
                        padding: 15px ;
                        margin-bottom: 20px;
                    }
                }
                .inquiry-fields-box{
                    margin-top: 20px;
                    
                    .primary-btn{
                        width: 100%;
                        padding: 14px;
                        margin-bottom: 30px;
                    }
                    .inquiry-field{
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;
                        margin: 0;
                        row-gap: 8px;
                        margin-bottom: 16px;
                        width: 100%;
                        label{
                            margin: 0;
                            font-size: 15px;
                            font-weight: 400;
                        }
                        .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
                            width: 100%;
                            
                        }
                        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
                            padding: 14px;
                        }
                        .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
                            width: 100%;
                        }
                        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
                            width: 100%;
                            border-radius: 9px;
                        }
                    }
                }
            }

            .side-bar-image{
                width: 100%;
                height: 250px;
                margin-top: 27px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }

            .google-map{
                width: 100%;
                height: 250px;
                margin-top: 30px;
                img{
                    width: 100%;
                }
            }
        }

        
        .hotel-content-section{
            width: 66%;
            margin: 0;
            @media only screen and (max-width: 800px) {
                width: 96%;
                margin: 0 auto !important;
            }
          

            .hotel-info{
                border-bottom: 1px solid lightgray;
                padding-bottom: 35px;
                margin-bottom: 40px;
                h2{
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 36px;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 13px;
                }
            }

            .hotel-facility{
                border-bottom: 1px solid lightgray;
                padding-bottom: 50px;
                margin-bottom: 50px;
                h2{
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 36px;
                    margin-bottom: 23px; 
                }
                .hotel-facilities-icons{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    row-gap: 20px;
                    @media only screen and (max-width: 800px) {
                        flex-direction: column;
                        div{
                            width: 98% !important;
                            p{
                                width: 87% !important;
                            }
                        }
                    }
                   div{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 33%;
                    &:nth-last-child(1){
                        margin: 0;
                    }
                    svg{
                        margin: 0;
                        color: #5E6D77 !important;
                    }
                    p{
                        width: 81%;
                        color: #5E6D77;
                        font-size: 16px;
                    }
                   }
                }
            }

            .availaibity-card{
                border-bottom: 1px solid lightgray;
                padding-bottom: 35px;
                margin-bottom: 40px;
                h2{
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 36px;
                    margin-bottom: 23px; 
                }
                .availabitly-card{
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid lightgray;
                    border-radius: 20px;
                    height: 230px;
                    margin-top: 30px;
                    @media only screen and (max-width: 800px) {
                        flex-direction: column;
                        height: auto;
                        .card-image{
                            width: 100% !important;
                            img{
                                border-radius: 10px 10px 0px 0px !important;
                            }
                        }
                    }
                    .card-image{
                        width: 32%;
                        margin: 0;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 20px 0px 0px 20px;
                        }
                    }
                    .icons-box{
                        width: 38%;
                        padding-left: 19px;
                        @media only screen and (max-width: 800px) {
                            width: 100% !important;
                        }
                        .icons-div{
                            display: flex;
                            align-items: center;
                            margin-top: 50px;
                            div{
                                text-align: center;
                                p{
                                    font-size: 13px;
                                    color: #5E6D77;
                                }
                            }
                        }
                        h2{
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 26px;
                            padding-top: 30px;
                        }
                        svg{
                            border: 1px solid lightgray;
                            width: 55px;
                            height: 55px;
                            padding: 13px;
                            border-radius: 10px;
                            margin-right: 8px;
                            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                        }
                    }
                    .price-btn{
                        width: 27%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        @media only screen and (max-width: 800px) {
                            width: 100%;
                            border-top: 1px solid lightgray;
                            padding: 16px;
                            justify-content: start !important;
                            &::after{
                                display: none !important;
                                width: 0;
                            }
                            button{
                                margin: 0 !important;
                            }
                        }
                        &::after{
                            content: "";
                            width: 1px;
                            height: 72%;
                            color: lightgray;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background: lightgray;
                            margin: 0 auto;
                            margin-top: 36px;
                        }
                        button{
                            padding: 16px 23px;
                            font-weight: 600;
                            margin-right: 20px;
                        }
                    }
                }
            }

            .hotel-rules{
                border-bottom: 1px solid lightgray;
                padding-bottom: 35px;
                margin-bottom: 30px;
                h2{
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 36px;
                    margin-bottom: 23px; 
                }
                .checkin-time{
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    p{
                        margin: 0;
                        width: 50%;
                        color: #5E6D77;
                        font-size: 16px;
                        margin-top: 14px;
                    }
                }
            }

          
        }
    }

    .recommended-container{
        margin-top: 59px;
        border-top: 1px solid lightgray;
        padding-top: 100px;
        position: relative;
        .recommend-heading{
            text-align: start;
            color: #1A2B48;
            font-family: "DM Sans", Sans-serif;
            font-size: 36px;
            font-weight: 700;
            line-height: 46px;
            margin-bottom: 65px;
        }
        .next-slider-icon{
            font-size: 30px;
            border-radius: 18px;
            position: absolute;
            top: -36px;
            right: 46px;
            display: block;
            @media screen and (max-width:800px){
                display: none;
            }
            svg{
                color: black;
                width: 40px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #DEDEDE;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                border-radius: 50%;
                padding: 12px;
                color: gray;
            }
        }
        .card-details{
            background-color: white;
            padding: 17px;
            border-radius: 0px 0px 15px 15px;
            padding-bottom: 24px;
            margin-bottom: 10px;
            box-shadow: 1px 1px 4px 2px #f1f1f1;
            position: relative;
            margin-right: 2px;
            margin-left: 2px;
        }
        .previous-slider-icon{
            font-size: 30px;
            border-radius: 18px;
            position: absolute;
            right: 86px;
            top: -37px;
            display: block;
            @media screen and (max-width:800px){
                display: none;
            }
            svg{
                color: black;
                width: 40px;
                height: 40px;
                padding: 12px;
                color: gray;
                background: #FFFFFF;
                border: 1px solid #DEDEDE;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                border-radius: 50%;
            }
        }
        .slick-dots li.slick-active button:before{
            color: #3B71FE;
            font-size: 12px;
        }
    }
}
.guest-text-box{
    cursor: pointer;
}