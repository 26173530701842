.image-carousel{
    padding-left: 15px;
    padding-right: 10px;
    margin-top: 40px;
    .ril__navButtonPrev{
        border-radius: 36px;
        left: 15px;
    }
    .ril__navButtonNext{
        right: 15px;
        border-radius: 36px;
    }
   
    .images-section{
        display: flex;
        align-items: center;
        .big-img{
            width: 33.3333%;
            height: 650px;
            @media only screen and (max-width: 800px) {
                display: none;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px 0px 0px 20px;
                cursor: pointer;
            }
        }
       .video-modal{
        position: relative;
        
       }
       
        .last-image{
            width: 48%;
            position: relative;
            border-radius: 0px 0px 20px 0px;
            @media only screen and (max-width: 800px) {
                width: 98%;
                border-radius: 20px !important;
                height: 300px;
                img{
                    border-radius: 20px !important;
                    display: block !important;
                    cursor: pointer;
                    height: 100%;
                    object-fit: cover;
                }
            }
            img{
                width: 100% !important;
                height: 100%;
                object-fit: cover;
                border-radius: 0px 0px 20px 0px;
                cursor: pointer;
            }
            .play-icons{
                display: flex;
                align-items: center;
                width: 41%;
                position: absolute;
                bottom: 21px;
                right: 26px;
                cursor: pointer;
                svg{
                    color: white;
                }
                .video-icon{
                    background: #00000063;
                    width: 40px;
                    height: 40px;
                    padding: 15px;
                    border-radius: 27px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #ffffff94;
                    justify-content: center;
                }
                .all-photos{
                    display: flex;
                    background: #00000063;
                    border: 1px solid #ffffff94;
                    border-radius: 27px;
                    padding: 10px 20px;
                    grid-column-gap: 9px;
                    align-items: center;
                    column-gap: 9px;
                    cursor: pointer;
                    svg{
                        width: 1.3rem;
                        height: 1.3rem;
                    }
                    p{
                        color: white;
                        font-size: 14px;
                    }
                }
            }
        }
        .sub-imgs{
            display: flex;
            width: 76%;
            flex-wrap: wrap;
            height: 650px;
            row-gap: 13px;
            @media only screen and (max-width: 800px) {
                width: 100%;
                height: 290px;
                img{
                    &:nth-child(1){
                        display: none ;
                    }
                }
                img{
                    &:nth-child(02){
                        display: none !important;
                    }
                }
                img{
                    &:nth-child(03){
                        display: none !important;
                    }
                }
            }
            img{
                width: 48%;
                cursor: pointer;
                height: 319px;
               &:nth-child(02){
                border-radius: 0px 20px 0px 0px;
                
               } 
               &:nth-last-child(1){
                border-radius: 0px 0px 20px 0px;
               }
            }
        }
    }
}

.modal-close-btn{
    position: absolute;
    right: 10px;
    background: white;
    z-index: 99999999;
    top: 13px;
    width: 49px;
    height: 50px;
    padding: 12px;
    border-radius: 24px;
    color: gray;
    cursor: pointer;
}


.-iamge-container{
    max-width: 1260px;
}