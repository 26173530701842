.blog-section{
    position: relative;
    .blog-heading{
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 58px;
    }
    .next-slider-icon{
        font-size: 30px;
        border-radius: 18px;
        position: absolute;
        top: -36px;
        right: 46px;
        display: block;
        @media screen and (max-width:800px){
            display: none;
        }
        svg{
            color: black;
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #DEDEDE;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
            border-radius: 50%;
            padding: 12px;
            color: gray;
        }
    }
    .previous-slider-icon{
        font-size: 30px;
        border-radius: 18px;
        position: absolute;
        right: 86px;
        top: -37px;
        display: block;
        @media screen and (max-width:800px){
            display: none;
        }
        svg{
            color: black;
            width: 40px;
            height: 40px;
            padding: 12px;
            color: gray;
            background: #FFFFFF;
            border: 1px solid #DEDEDE;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
            border-radius: 50%;
        }
    }
    .slick-dots li.slick-active button:before{
        color: #3B71FE;
        font-size: 12px;
    }
    
}