.call-to-action{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 125px;
    border: 1px solid lightgray;
    border-radius: 20px;
    height: 350px;
    @media only screen and (max-width: 800px) {
        flex-direction: column;
        height: 100%;
    }
    .call-image{
        width: 50%;
        height: 350px;
        @media only screen and (max-width: 800px) {
            width: 100%;
        }
        img{
            width: 100%;
            border-radius: 20px 0px 0px 20px;
            height: 100%;
            object-fit: cover;
            @media only screen and (max-width: 800px) {
                border-radius: 20px 20px 0px 0px;
            }
        }
    }
    .call-details{
        width: 55%;
        padding: 50px 50px 50px 50px;
        @media only screen and (max-width: 800px) {
            width: 100%;
            padding: 32px;
        }
        h2{
            font-size: 36px;
            font-weight: 700;
            line-height: 46px;
            padding: 0px 50px 0px 50px;
            text-align: center;
        }
        p{
            color: #727272;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            padding: 0px 50px 0px 50px;
            text-align: center;
        }
        .input-subscribe{
            border: 1px solid lightgray;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 86%;
            border-radius: 37px;
            padding: 5px;
            margin-top: 32px;
            input{
                border: none;
                font-size: 18px;
                width: 80%;
                margin-left: 15px;
                &:focus{
                    outline: none;
                }
            }     
            button{
                background: #3B71FE;
                color: white;
                border: none;
                padding: 16px 27px;
                border-radius: 33px;
                font-size: 16px;
            }   
        }
    }
}