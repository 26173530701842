.custom-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 80px;
    .pagination{
        display: flex;
        justify-content: space-between;
        align-items: center;
        li{
            list-style: none;
            margin-right: 20px;
        }
        span{
            padding: 8px;
            border-radius: 35px;
            width: 40px ;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid lightgray;
            border-radius: 10px;
            cursor: pointer;
            svg{
                color: grey;
            }
        }
        .disabled-icon{
            stroke: #999999 !important;
        }
        .active-icon{
            stroke: #052460 !important;
        }
        .active{
            color: white;
            font-size: 19px;
            background-color: #3B71FE;
            border-radius: 7px;
            padding: 5px 12px;
            border: none;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 10px;
            box-shadow: 0 1px 2px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.05);
        }
        .not-active{
            color: #999999;
            font-size: 18px;
            border: none;
            margin-right: 10px;
            background-color: transparent;
        }
        .disabled{
            color: #999999;
            
        }
        button{
            background-color: white;
            color: #999999;
        }
    }
}