.blog-card{
    width: 97%;
    overflow: hidden;
    .blog-image-box{
        height: 250px;
        width: 100%;
        overflow: hidden;
        border-radius: 18px 18px 0px 0px;
    }
    .background-img{
        height: 100%;
        background-size: cover;
        border-radius: 18px 18px 0px 0px;
        background-position: center;
        transition: transform 0.8s;
        width: 99%;
        &:hover{
            transform: scale(1.1, 1.1);
        }
    }
    .blog-details{
        box-shadow: 1px 1px 3px 1px #e7e7e7;
        padding: 15px;
        border-radius: 0px 0px 18px 18px;
        margin-bottom: 30px;
        padding-bottom: 25px;
        width: 99%;
        h2{
            font-size: 20px;
            margin-bottom: 11px;
            cursor: pointer;
            &:hover{
                color: #0d6efd;
                transition: .3s;
            }
        }
        p{
            color: #727272;
            line-height: 1.6rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 3.2rem;
        }
        .travel{
            background: rgba(1, 115, 44, 0.06);
            padding: 8px ;
            border: none;
            border-radius: 20px;
            color: #01732c;
            text-transform: uppercase;
            font-size: 13px;
            display: flex;
            margin-bottom: 15px !important;
            margin-top: 14px !important;
            position: relative;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            font-weight: 700;
            &:before{
                content: "";
                width: 10px;
                height: 10px;
                background-color: #01732c;
                border-radius: 10px;
                display: block;
                margin-right: 12px;
            }
        }
        .booking{
            background: rgba(136,7,53,0.06);
            padding: 8px ;
            border: none;
            border-radius: 20px;
            color: rgba(136,7,53,1);
            text-transform: uppercase;
            font-size: 13px;
            margin-bottom: 15px !important;
            margin-top: 14px !important;
            position: relative;
            align-items: center;
            display: flex;
            font-weight: 700;
            justify-content: space-between;
            margin: 0;
            &:before{
                content: "";
                width: 10px;
                height: 10px;
                background-color: rgba(136,7,53,1);
                border-radius: 10px;
                display: block;
                margin-right: 12px;
            }
        }
    }
}