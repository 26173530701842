.reviews-container{
    .primary-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 14px 26px;
        font-weight: 600;
    }
    .review-pagination{
        .custom-pagination{
            margin: 0;
            justify-content: start;
            margin-bottom: 40px;
            border-bottom: 1px solid lightgray;
            padding-bottom: 40px;
            margin-top: 40px;
            .pagination{
                margin: 0;
            }
        }
    }
    .review-rating-text{
        background: #3b71fe;
        width: 32px;
        margin: 0;
        color: white !important;
        border-radius: 6px;
        padding: 2px 5px;
        font-size: 14px !important;
    }
    .review-heading{
        font-weight: 700;
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 23px; 
    }

    .tour-review-total-box{
        border: 1px solid lightgray;
        padding: 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        background-color: #FCFCFC;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            .reviews-icons-box{
                width: 100% !important;
                border-left: none !important;
            }
        }
        .review-stars{
            column-gap: 10px;
            .star-tour{
                column-gap: 9px;
                display: flex;
                align-items: center;
                p{
                    color: #3B71FE;
                    font-size: 30px;
                }
            }
            svg{
                margin: 0;
                color: #FFB21D;
            }
            h2{
                font-size: 27px;
                margin-top: 7px;
                margin-bottom: 10px;
            }
            p{
                margin: 0;
                font-weight: 700;
                font-size: 22px;
                line-height: 32px;
                text-align: center;
            }
            span{
                margin: 0;
                font-size: 16px;
                color: #5E6D77;
            }
        }
        .reviews-icons-box{
            row-gap: 19px;
            margin-top: 23px;
            width: 60%;
            border-left: 1px solid lightgray;
            padding-left: 70px;
            margin-right: 0;
            margin-left: 0;
            .reviews-progress{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 7px;
                p{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    margin: 0px;
                    width: 30%;
                   
                    &:nth-last-child(1){
                        text-align: end;
                        width: 17%;
                        margin-right: 16px;
                    }
                }
                .progress-bar{
                    width: 48%;
                    background: #4CBB7F;
                    height: 4px;
                    border-radius: 11px;
                    margin: 0px;
                    margin-left: 40px;
                    .progress-line{
                        width: 32%;
                        background: #4CBB7F;
                        height: 4px;
                        border-radius: 11px;
                        margin: 0;
                    }
                }
            }
        }
    }
    .reviews-total-box{
        border: 1px solid lightgray;
        padding: 30px;
        border-radius: 20px;
        background: #FCFCFC;
        .review-stars{
            display: flex;
            align-items: center;
            column-gap: 10px;
            svg{
                margin: 0;
                color: #FFB21D;
            }
            p{
                margin: 0;
                font-weight: 700;
                font-size: 22px;
                line-height: 32px;
            }
            span{
                margin: 0;
                font-size: 16px;
                color: #5E6D77;
            }
        }
        .reviews-icons-box{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 19px;
            margin-top: 23px;
            @media only screen and (max-width: 800px) {
                flex-direction: column;
                .reviews-progress{
                    width: 100% !important;
                }
            }
            .reviews-progress{
                display: flex;
                align-items: center;
                width: 46%;
                justify-content: space-between;
                p{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    margin: 0px;
                    width: 30%;
                   
                    &:nth-last-child(1){
                        text-align: end;
                        width: 17%;
                        margin-right: 16px;
                    }
                }
                .progress-bar{
                    width: 48%;
                    background: #4CBB7F;
                    height: 4px;
                    border-radius: 11px;
                    margin: 0px;
                    margin-left: 40px;
                    .progress-line{
                        width: 32%;
                        background: #4CBB7F;
                        height: 4px;
                        border-radius: 11px;
                        margin: 0;
                    }
                }
            }
        }
    }

  

    .total-review-desc{
        text-align: center;
        margin-top: 29px;
        margin-bottom: 17px;
        color: gray;
        font-size: 14px;
        border-bottom: 1px solid lightgray;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    .reviews-desc-box{
        .reviews-desc{
            border-bottom: 1px solid lightgray;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
        .review-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .review-avatar{
                display: flex;
                align-items: center;
                margin: 0;
                column-gap: 12px;
                p{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                }
                svg{
                    color: lightgray;
                    width: 3.5rem;
                    height: 3.5rem;
                }
                .date{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: center;
                    color: #74818a;
                    margin-left: -14px;
                }
            }
            .like-icon{
                display: flex;
                align-items: center;
                margin: 0;
                svg{
                    margin-right: 7px;
                }
            }
        }
        .stars-icons{
            margin-top: 15px;
            svg{
                color: #FFB21D;
                margin-right: 7px;
            }
            p{
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: #74818a;
                margin-top: 20px;
                margin-left: 13px;
            }
        }
    }

    .review-fields{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        column-gap: 20px;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            row-gap: 20px;
        }
        .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
            width: 100%;
        }
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
            padding: 13px;
        }
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
            width: 100%;
            border-radius: 8px;
        }
    }
    .title-field{
        .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
            width: 100%;
        }
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
            padding: 13px;
        }
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
            width: 100%;
            border-radius: 8px;
        }
    }
    .reviews-form{
        h2{
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            margin-top: 25px;
            margin-bottom: 13px;
        }
        .required-text{
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            margin-top: 10px;
            margin-bottom: 30px;
            color: #5E6D77;
        }
        .primary-btn{
            margin-top: 40px;
            padding: 20px;
            font-weight: 600;
        }
    }
    .reviews-stars{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid lightgray;
        border-radius: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 23px;
        row-gap: 17px;
        padding-top: 35px;
        padding-bottom: 35px;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            div{
                width: 100% !important;
            }
        }
        div{
            width: 48%;
            display: flex;
            align-items: center;
            margin: 0;
            .active-icon{
                color: #FFB21D;
            }
            label{
                margin-right: 10px;

            }
           
            svg{
                color: #A1ABB3;
                font-size: 15px;
            }
            p{
                margin: 0;
                font-size: 15px;
                width: 40%;
            }
        }
    }
    .content-field{
        .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
            width: 100%;
        }
        .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
            width: 100%;
        }
    }
   
}