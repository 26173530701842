.landing-page{
 
    .hero-image-banner{
        background-image: linear-gradient(rgb(0 0 0 / 17%), rgb(0 0 0 / 67%)), url("https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
        height: 540px;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 130px;
        background-position: center;
        @media only screen and (max-width: 800px) {
            height: 400px;
            margin-bottom: 350px;
        }
        @media only screen and (max-width: 500px) {
            height: 462px;
            margin-bottom: 405px;
        }
        .hero-text{
            text-align: center;
            h2{
                color: #fff;
                font-size: 64px;
                font-weight: 700;
                padding-top: 127px;
                margin-bottom: 14px;
                @media only screen and (max-width: 800px) {
                    font-size: 45px;
                    padding-top: 67px;
                    padding-bottom: 19px;
                    margin-left: 15px;
                    margin-right: 13px;
                }
            }
            p{
                color: white;
                font-size: 18px;
            }
        }

        .search-bar-tabs{
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 43%;
            padding-top: 60px;
            @media only screen and (max-width: 800px) {
                width: 100%;
                padding-top: 31px;
                flex-wrap: wrap;
                margin-left: 15px;
                width: 95%;
                margin-right: 15px;
            }
            .active-search{
                border-bottom: 1px solid white;
                color: white;
                cursor: pointer;
                font-size: 14px;
                padding-bottom: 5px;
            }
            .not-active-search{
                border-bottom: none;
                color: white;
                cursor: pointer;
                padding-bottom: 5px;
                font-size: 14px;
            }
        }
    }

    .sale-banners{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            row-gap: 10px;
        }
        .banner{
            width: 49%;
            overflow: hidden;
            border-radius: 20px;
            @media only screen and (max-width: 800px) {
                width: 100%;
            }
            img{
                width: 100%;
                object-fit: contain;
                transform: scale(1, 1);
                transition: transform 0.4s;
                border-radius: 20px;
                &:hover{
                    transform: scale(1.1);
                    border-radius: 20px;
                }
            }
        }
    }

    .top-destinations{
        h2{
            font-size: 36px;
            font-weight: 700;
            line-height: 46px;
            text-align: center;
            margin-top: 80px;
            margin-bottom: 50px;
        }
        .destinations-container{
            display: grid;
            grid-template-columns: 32.5% 32.5% 32.5%;
            grid-row-gap: 19px;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: 800px) {
                grid-template-columns:100%;
            }
            .single-destination-card{
                width: 100%;
                height: 270px;
                overflow: hidden;
                border-radius: 9px;
                position: relative;
                
                .banner-heading{
                    color: white;
                    font-size: 33px;
                    text-align: center;
                    margin-bottom: 1px;
                    width: 50%;
                    pointer-events: none;
                }
                .destination-content{
                    position: absolute;
                    top: 32%;
                    pointer-events: none;
                    @media only screen and (max-width: 800px) {
                        left: 7% !important;
                    }
                    a{
                        text-decoration: none;
                        pointer-events: all !important;
                    }
                }
                .destination-titles{
                    display: flex;
                    row-gap: 5px;
                    column-gap: 3px;
                    width: 64%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 6px;
                    flex-wrap: wrap;
                    
                    li{
                        font-size: 16px;
                        color: white;
                            list-style: none;
                            position: relative;
                            margin-right: 12px;
                        &:before{
                            content: ".";
                            font-size: 45px;
                            color: white;
                            position: absolute;
                            top: -31px;
                            left: -14px;
                        }
                    }
                }
                .background-image{
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    position: relative;
                    overflow: hidden;
                    transform: scale(1, 1);
                    transition: transform 0.8s;
                    opacity: 0.9;
                  
                    &:hover{
                        transform: scale(1.1, 1.1);
                    }
                   
                }
              
            }
          
        }
    }

 

 
    
}