.rental-card{
    .review-box{
        padding-bottom: 20px !important;
    }
    .price-tag{
        margin-top: 0px !important;
    }
    .facility-icons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        padding-top: 18px;
        padding-bottom: 9px;
        div{
            display: flex;
            align-items: center;
            margin: 0;
            justify-content: start;
            svg{
                color: #5E6D77;
                font-size: 22px;
                margin-right: 1px;
            }
            p{
                color: #5E6D77;
                font-size: 14px;
            }
        }
    }
    .price-tag{
        margin-top: 0px !important;
        padding-top: 2px !important;
        p{
            margin: 0;
            margin-left: 10px !important;
        }
    }
    .price-tab-cars{
        margin-top: 10px !important;
    }
    .cars-icons{
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgray;
        padding-bottom: 22px;
        width: 100%;
        margin: 0;
        padding-top: 18px;
        div{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0;
            width: 25%;
            svg{
                color: #5E6D77;
                border: 1px solid lightgray;
                border-radius: 7px;
                width: 45px;
                height: 45px;
                padding: 8px;
                margin-right: 13px;
            }
            p{
                color: #5E6D77;
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }
  
}

.car-rental-card{
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
    .card-image-box{
        width: 93%;
        margin-top: 10px;
        border-radius: 15px;
    }
    .card-details{
        margin-bottom: 0 !important;
    }
}