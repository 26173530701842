.recommended-places{
    background-color: #F7F8FA;
    padding-bottom: 40px;
    padding-top: 70px;
    margin-top: 100px;
    margin-bottom: 80px;
    .recommend-heading{
        text-align: center;
        color: #1A2B48;
        font-family: "DM Sans", Sans-serif;
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 20px;
    }
    .recommended-tabs{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 49%;
        margin-top: 30px;
        margin-bottom: 50px;
        @media screen and (max-width:800px){
            width: 100%;
            flex-wrap: wrap;
            row-gap: 14px;
        }
        .active-btn{
            background-color:  #3B71FE;
            border: none;
            border-radius: 8px;
            padding: 10px 29px;
            color: white;
            font-size: 17px;
            cursor: pointer;
            font-weight: 500;
        }
        .not-active-btn{
            background-color: white;
            border: none;
            padding: 10px 29px;
            border-radius: 8px;
            font-weight: 500;
            color: black;
            font-size: 17px;
            cursor: pointer;
            border: 1px solid lightgray;
        }
    }
    .recommended-container{
        display: grid;
        grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
        grid-row-gap: 15px;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 800px) {
            grid-template-columns:100%;
        }
        .recommended-card{
            width: 100%;
        }
    }
}