*{
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Caramel&family=DM+Sans&family=Roboto:wght@300&family=Satisfy&family=Sevillana&display=swap');

.container{
    max-width: 1140px;
    margin: 0 auto;
    @media only screen and (max-width: 800px) {
        margin-left: 15px;
        margin-right: 15px;
        overflow-x: hidden;
    }
}

body{
    font-family: 'DM Sans', sans-serif;
    overflow-x: hidden;
}

.primary-btn{
    background-color: #3B71FE;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 21px 35px;
    font-size: 15px;
}
button{
    cursor: pointer;
}