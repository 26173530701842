.hotel-search-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-radius: 52px;
    width: 69%;
    margin-top: 37px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 14px;
    position: relative;
    padding-left: 26px;
    max-width: 1000px;
   
    @media only screen and (max-width: 1100px) {
        width: 95% !important;
    }
    @media only screen and (max-width: 1280px) {
        width: 80%;
    }
    @media only screen and (max-width: 850px) {
        flex-direction: column;
        width: 95% !important;
        margin-top: 101px;
        box-shadow: 0 0 5px 2px lightgray;
        margin-right: 15px;
        margin-left: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .second-btn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        font-weight: 600;
        margin-right: 3px;
        background-color: #3B71FE;
        color: white;
        border: none;
        border-radius: 50px;
        padding: 21px 35px;
        font-size: 15px;
        @media only screen and (max-width: 850px) {
            width: 95%;
            justify-content: center;
            align-items: center;
            svg{
                margin: 0;
            }
        }
    }
    .primary-btn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        font-weight: 600;
        position: relative;
        margin: 0;
        &:before{
            content: "";
            border-left: 1px solid lightgray;
            height: 33px;
            width: 2px;
            top: 15px;
            position: absolute;
            left: -15px;
        }
    }
    .date-picker{
        position: relative;
        margin-left: 15px;
        // #date_picker_end_date_id{
        //     margin-left: 13px;
        // }
        svg{
            color: #5E6D77;
        }
        .check-out-tct{
            position: absolute;
            top: 3px;
            right: -29px;
            font-size: 16px;
            font-weight: 800 !important;
            z-index: 99;
            @media screen and (max-width:850px){
                left:197px !important ;
            }
        }
        .date-end-calender{
            position: absolute;
            right: 60px;
            top: 18px;
            z-index: 99;
            @media screen and (max-width:850px){
                left: -49px !important;
                top: 14px;
            }
        }
        .check-in-text{
            position: absolute;
            top: 3px;
            font-size: 16px;
            font-weight: 800 !important;
            z-index: 99;
            left: 33px;
        }
        #date_picker_end_date_id{
            margin-left: 40px;
        }
        .DateInput{
            width: 100px !important;
        }
        .DateInput_input{
            font-size: 14px !important;
            color: lightgray;
            padding: 0 !important;
            padding-left: 11px !important;
            padding-top: 5px !important;
            margin-top: 8px;
        }
        .DateRangePicker{
            margin-top: 14px;
        }
        @media screen and (max-width:850px){
            margin: 0;
            width: 100%;
            margin-bottom: 12px;
            margin-top: 12px;
        }
    }
    .DateRangePickerInput__withBorder{
        border: none;
    }
    .DateRangePickerInput_calendarIcon{
        margin: 0;
        padding: 0;
    }
    .location-bar{
        // border-right: 1px solid lightgray;
        padding-right: 11px;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        width: 23%;
        margin-left: 0;
        &:after{
            content: "";
            border-left: 1px solid lightgray;
            height: 33px;
            width: 2px;
            top: 9px;
            position: absolute;
            right: -2px;
            @media only screen and (max-width: 850px) {
                border-left: none;
                width: 0px;
                height: 0px;
            }
        }
        @media only screen and (max-width: 850px) {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid lightgray;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
        
        &:nth-last-child(1){
            border-right: none;
        }
        .location-text{
            width: 89%;
        }
        .heading{
            display: flex;
            align-items: start;
            justify-content: start;
            margin-bottom: 2px; 
            svg{
                width: 12%;

                margin-top: 5px;
            }  
            .location-heading-top{
                font-size: 16px;
                margin-bottom: 7px;
                font-weight: 600;
            }
            p{
                width: 80%;
                @media  screen and (max-width:850px){
                    width: 99%;
                    font-size: 18px;
                }
            }
        }
        .inof-text{
            border: none;
            font-size: 14px;
            color: #5e6d77a3;
            white-space: nowrap;
            margin-left: 17px;
            margin-top: 1px;
            font-weight: 500 !important;
            @media  screen and (max-width:850px){
                margin-left: 0px;
            }
           
        }
        .location-dropdown{
            position: absolute;
            top: 90px;
            background: white;
            z-index: 9;
            padding: 24px;
            border: 1px solid #DEDEDE;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            left: -21px;
            width: 294px;
            height: 303px;
            overflow: auto;
            .location-heading{
                font-size: 18px;
                border-bottom: 1px solid lightgray;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            span{
                color: #5E6D77;
                font-size: 18px;
            }
            .locations{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                justify-content: start;
                width: 100%;
                margin-top: 17px;
                p{
                    width: 88%;
                    color: #5E6D77;
                }
                svg{
                    width: 10%;
                    color: #5E6D77;
                }
            }
        }
    }
    .guest-bar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 18%;
        position: relative;
        // border-left: 1px solid lightgray;
        padding-right: 11px;
        margin-right: 0;
        &:before{
            content: "";
            border-left: 1px solid lightgray;
            height: 33px;
            width: 2px;
            top: 9px;
            position: absolute;
            left: -8px;
            @media only screen and (max-width: 850px) {
                border-left: none;
                width: 0;
                height: 0;
            }
        }
        p{
            font-weight: 600;
            padding-bottom: 2px;
            margin-bottom: 3px;
        }
        @media only screen and (max-width: 850px) {
            width: 100%;
            border-left: none;
            border-top: 1px solid lightgray;
            justify-content: start;
            svg{
                margin: 0;
            }
            .guest-text-box{
                margin: 0;
                margin-left: 17px;
                margin-bottom: 14px;
                padding-top: 13px;
                cursor: pointer;
               
            }
        }
        .room-guests{
            font-size: 14px;
            color:  #5e6d77a3;
        }
        .guest-dropdown{
            position: absolute;
            top: 72px;
            left: 26px;
            background: white;
            z-index: 9;
            padding: 19px;
            border-radius: 12px;
            box-shadow: 1px 2px 3px 1px lightgray;
            width: 273px;
            .counter-box{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                border-bottom: 1px solid #e1e1e1;
                padding-bottom: 14px;
                &:nth-last-child(1){
                    border-bottom: none;
                }
                .counter{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 49%;
                    svg{ 
                        border: 1px solid lightgray;
                        border-radius: 40px;
                        font-size: 25px;
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }
    }
}

.other-search-bar{
    width: 55% !important;
    @media only screen and (max-width: 1100px) {
        width: 95% !important;
    }
    .inof-text{
        white-space: nowrap;
        font-weight: 500 !important;
    }
    .location-bar{
        width: 28% !important;
    }
}
.rentalguest-box{
    margin-left: 0 !important;
}
.guest-svgIcon{
    margin-right: 15px;
    margin-left: 13px;
}
#date_picker_end_date_id1{
    margin-left: 54px !important;
}