.property-card{
    overflow: hidden;
    .card-image-box{
        height: 190px;
        width: 100%;
        overflow: hidden;
        border-radius: 15px 15px 0px 0px;
        position: relative;
    }
    .fav-icon{
        position: absolute;
        top: 9px;
        right: 16px;
        font-size: 24px;
        color: white;
    }
    .card-background{
        height: 100%;
        width: 100%;
        position: relative;
        background-size: cover;
        border-radius: 15px 15px 0px 0px;
        transition: transform 0.8s;
        background-repeat: no-repeat;
        @media screen and (max-width:800px){
            height: 250px;
        }
        &:hover{
            transform: scale(1.1, 1.1);
            border-radius: 15px 15px 0px 0px;
        }
      
      
    }
    .avatar-img{
        width: 40px;
        height: 40px;
        border-radius: 50px;
        position: absolute;
        top: -19px;
        right: 13px;
        border: 1px solid white;
        object-fit: cover;
    }
    .card-details{
        background-color: white;
        padding: 17px;
        border-radius: 0px 0px 15px 15px;
        padding-bottom: 24px;
        margin-bottom: 10px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
       position: relative;
       a{
        text-decoration: none;
        color: black;
       }
        h3{
            text-align: start;
            cursor: pointer;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            margin-top: 0;
            margin-bottom: 5px;
            &:hover{
                color: #0d6efd;
                transition: .3s;
            }
        }
        .location{
            color: #5E6D77;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            svg{
                font-size: 16px;
                height: 15px;
            }
        }
       
        .review-stars{
            padding-bottom: 10px;
            svg{
                color: #FA5636;
                font-size: 13px;
                margin-right: 5px;
            }
        }
        .price-tag{
            p{
                font-size: 14px;
                color: #5E6D77;
                span{
                    color: black;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
        .review-box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid lightgray;
            margin-top: 20px;
            padding-top: 20px;
            margin-bottom: 10px;
            .review-number{
                background: #F9FBFF;
                border: 1px solid #B8CCFF;
                padding: 2px 10px;
                border-radius: 4px;
                color: #3b71fe;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 2.3px;
                line-height: 20px;
                margin: 0;
            }
            .review-no{
                color: #5E6D77;
                font-size: 14px;
                margin-left: -13px;
            }
            span{
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                margin-left: 11px;
            }
        }
    }
}