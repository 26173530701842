.destination-page{
    .recommended-places{
        background-color: white;
        margin-top: 60px;
    }
    .blog-destination{
        background-color: #F7F8FA;
        padding: 30px;
    }
    
    .carousel-images{
        margin-top: 75px;
        @media screen and (max-width: 800px){
            height: 500px;
        }
        .tour-images{
            height: 630px;
            width: 98%;
        }
        .next-slider-icon{
            background: #0000009e;
            color: white;
            right: 27px;
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 27px;
            top: 50%;
            cursor: pointer;
            z-index: 1;
            &:hover{
                background-color: white;
                svg{
                    color: black;
                }
            }
            svg{
                position: absolute;
                right: 15px;
                color: white;
                font-size: 17px;
            }
        }
        .previous-slider-icon{
            background: #0000009e;
            color: white;
            left: 42px;
            cursor: pointer;
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 27px;
            top: 50%;
            z-index: 1;
            &:hover{
                background-color: white;
                svg{
                    color: black;
                }
            }
            svg{
                position: absolute;
                left: 15px;
                color: white;
                font-size: 17px;
            }
        }
        .slick-slide{
            img {
                height: 100%;
                width: 98%;
                object-fit: cover;
                margin: 2px auto 0px;
                box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 10%);
                cursor: grab;
                @media screen and (max-width: 600px){
                    height: 450px;
                }
            }
        }
       

    }
    .destination-content{
        display: flex;
        justify-content: space-between;
        margin-top: 75px;
        @media screen and (max-width: 800px){
            flex-direction: column;
            row-gap: 20px;
            .content{
                width: 98% !important;
            }
            .contact-us-google{
                width: 98% !important;
            }
        }
        .content{
            width: 63%;
            p{
                color: #a3a3a3;
                font-family: "DM Sans", Sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                width: 96%;
                margin: 0;
                text-align: justify;
            }
            h2{
                font-size: 26px;
                font-weight: 700;
                line-height: 36px;
                margin: 0px 0px 20px 0px;
            }
        }
        .contact-us-google{
            width: 33%;
            img{
                width: 100%;
            }
        }

        // .contact-us-google{
        //     margin-bottom: 650px;
        //     .placeName{
        //         color: #333;
        //     }
        //     .map-logo{
        //         text-align: center;
        //         width: 100%;
        //         margin-bottom: 10px;
        //         img{
        //             filter: invert(1);
        //             width: 100px;
        //             height: 100px;
        //         }
        //     }
        //     .address-text{
        //         color: #666666;
        //         margin-bottom: 0;
        //     }
        //     @media screen and (max-width:567px) {
        //         margin-bottom: 600px;
        //     }
        // }
    }
}