.car-rental-detail{
    .review-box{
        width: 37% !important;
        svg{
            color: #FFB21D;
        }
    }
    .social-share-icons{
        z-index: 99;
    }
    .total-price{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        p{
            font-size: 19px;
            font-weight: 800;
            margin: 0rem;
        }
    }
    .location-bar{
        border-right: 1px solid lightgray;
        padding-right: 11px;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        margin-left: 0;
        border-bottom: 1px solid lightgray;
        padding-top: 23px;
        margin-bottom: 11px;
        padding-bottom: 19px;
        @media only screen and (max-width: 800px) {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid lightgray;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
        
        &:nth-last-child(1){
            border-right: none;
        }
        .location-text{
            width: 89%;
        }
        .heading{
            margin-left: 12px;
            svg{
                width: 12%;

                margin-top: 5px;
            }  
            .location-heading-top{
                font-size: 18px;
                margin-bottom: 5px;
                margin-left: 13px;
                width: 100%;
            }
            p{
                width: 80%;
                @media  screen and (max-width:800px){
                    width: 90%;
                    font-size: 18px;
                }
            }
        }
        .inof-text{
            border: none;
            font-size: 15px;
            color: black;
            margin-left: 26px;
            margin-top: 1px;
            font-weight: 500 !important;
            @media  screen and (max-width:800px){
                padding-left: 40px;
            }
           
        }
        .location-dropdown{
            position: absolute;
            top: 83px;
            background: white;
            z-index: 100;
            padding: 24px;
            border: 1px solid #DEDEDE;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
            border-radius: 0;
            width: 342px;
            left: 0;
            height: 300px;
            overflow: auto;
            .location-heading{
                font-size: 18px;
                border-bottom: 1px solid lightgray;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            span{
                color: #5E6D77;
                font-size: 18px;
            }
            .locations{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                justify-content: start;
                width: 100%;
                margin-top: 17px;
                p{
                    width: 88%;
                    color: #5E6D77;
                }
                svg{
                    width: 10%;
                    color: #5E6D77;
                }
            }
        }
    }
    .date-picker{
        position: relative;
        border-bottom: none !important;
        .DateInput{
            padding-bottom: 8px;
            &:nth-child(1){
                width: 100% !important;
                padding: 10px;
                border-bottom: 1px solid lightgray;
                border-right: none !important;
            }
        }
        .DateInput_input{
            padding-top: 18px !important;
            padding-left: 11px !important;
            font-size: 17px !important;
            color: black;
        }
        .pickup-text{
            position: absolute;
            top: 9px;
            z-index: 99;
            left: 25px;
            font-size: 17px;
        }
        .dropoff-text{
            position: absolute;
            top: 86px;
            z-index: 99;
            left: 26px;
        }
    }
    .highlights-box{
        display: flex;
        align-items: center;
        margin: 0;
        border-top: 1px solid lightgray;
        padding-top: 20px;
        padding-bottom: 32px;
        border-bottom: 1px solid lightgray;
        margin-bottom: 40px;
        justify-content: space-between;
        div{
            display: flex;
            align-items: center;
            margin: 0;
            p{
                margin: 0;
                padding-left: 10px;
                padding-top: 20px;
                color: black;
                font-size: 16px;
                font-weight: 600;
            }
            .true-svg{
                margin: 0;
                background: #CEF2E5;
                color: #10AC58;
                border-radius: 30px;
                width: 25px;
                height: 24px;
                padding: 8px;
                margin-top: 16px;
            }
            .false-svg{
                margin: 0;
                background: #FAD6D6;
                color: #DA3838;
                border-radius: 30px;
                width: 25px;
                height: 24px;
                padding: 8px;
                margin-top: 16px;
            }
        }
       
    }
    .hotel-reviews-box{
        margin-bottom: 35px !important;
        margin-top: 0;
        .review-box{
            width: 27% !important;
        }
    }
    .tour-reviews-box{
        border-bottom: none !important;
        margin-top: 25px;
        padding-bottom: 2px;
       
    }
    .car-rental-slider{
        margin-bottom: 180px;
        &:hover{
            .previous-slider-icon{
                display: block;
            }
            .next-slider-icon{
                display: block;
            }
        }
        .slick-dots{
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            bottom: -114px !important;
            .slick-active{
                border: 2px solid blue;
                border-radius: 10px;
            }
            li{
                width: 24%;
                height: 102px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 7px;
                }
            }
        }
        .next-slider-icon{
            font-size: 30px;
            border-radius: 18px;
            display: block;
            z-index: 9;
            position: absolute;
            top: 50%;
            right: 31px;
            display: none;
            &:hover{
                svg{
                  color: black;
                  background-color: white;
                }
              }
            svg{
                width: 40px;
                height: 40px;
                padding: 12px;
                color: white;
                background: #00000063;
                border: 1px solid #DEDEDE;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                border-radius: 50%;
            }
        }
        .previous-slider-icon{
            font-size: 30px;
            border-radius: 18px;
            display: block;
            z-index: 9;
            position: absolute;
            top: 50%;
            left: 31px;
            display: none;
            &:hover{
                
              svg{
                color: black;
                background-color: white;
              }
            }
            svg{
                width: 40px;
                height: 40px;
                padding: 12px;
                color: white;
                background: #00000063;
                border: 1px solid #DEDEDE;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
                border-radius: 50%;
            }
        }
        .rental-img-box{
            width: 100% !important;
            height: 388px;
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
           
        }
    }
}