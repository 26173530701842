.breadcrumb-container{
    .container{
        max-width: 1260px;
    }
    .breadcrumb-image{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: scroll;
        position: relative;
        padding-bottom: 60px;
        border-bottom: 0;
        h1{
            color: #fff;
            font-size: 36px;
            line-height: 46px;
            font-weight: 700;
            margin-bottom: 0;
            padding-top: 65px;

        }
        ul{
            list-style-type: disc !important;
        }
        .breadcrumb-info{
            margin-top: 19px;
           
            li{
                position: relative;
                display: inline-block;
                padding-right: 20px;
                z-index: 0;
                color: white;
                margin-left: 13px;
                &:nth-child(1){
                    margin-left: 0;
                }
                &:before{
                    content: ".";
                    font-size: 45px;
                    color: white;
                    position: absolute;
                    top: -31px;
                    left: -14px;
                }
                &:nth-last-child(1){
                    color: #adadad;
                }
            }

        }
      
    }
}