.header-container{
    background: rgba(35,35,35,.6);
    padding: 23px 40px;
    position: relative;
    @media only screen and (max-width: 990px) {
        background-color: white;
    }
    .logo-image{
        width: 15%;
        margin: 0;
        @media only screen and (max-width: 990px) {
            width: 35%;
            img{
                width: 126px;
            }
        }
    }
    .navbar-box{
        max-width: 1380px;
        margin: 0 auto;
    }
    .active-nav{
        color: #5582f4 !important;
    }
    .main-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar{
        width: 40%;
        margin-left: 55px;
    
        @media screen and (max-width:990px){
            display: none;
        }
    }
    .navbars-main{
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{
            list-style: none;
            margin: 0;
            a{
                color: white;
                font-size: 16px;
                text-decoration: none;
                margin-left: 15px;
                display: flex;
                align-items: center;
                font-weight: 600;
                &:hover{
                    color: #5582f4;
                }
            }
            &:hover{
                .dropdown{
                    opacity: 1;
                    visibility: visible;
                }
            }
            .dropdown{
                position: absolute;
                top: 100%;
                padding-left: 0;
                margin-bottom: 0;
                min-width: 230px;
                padding-top: 10px;
                padding-bottom: 10px;
                background: #FFF;
                z-index: 999;
                opacity: 0;
                visibility: hidden;
                transition: all 0.2s ease-in-out;
                border: 1px solid #DEDEDE;
                box-sizing: border-box;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                a{
                    padding: 13px 0;
                    display: block;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    color: black;
                }
            }
        }
       
    }
    .right-navbar{
        width: 30%;
        display: flex;
        align-items: center;
        margin: 0;
        @media only screen and (max-width: 990px) {
            width: 20%;
        }
        button{
            background-color: #3B71FE;
            color: white;
            border: none;
            border-radius: 50px;
            padding: 16px 35px;
            font-weight: 600;
            @media screen and (max-width:990px){
                display: none;
            }
        }
        .card-section-icon{
            position: relative;
            padding-top: 5px;
            .cart-dropdown{
                border: 1px solid #DEDEDE;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                padding: 25px 30px;
                background: white;
                position: absolute;
                top: 69px;
                width: 275px;
                left: -176px;
                h5{
                    font-size: 23px;
                    border-bottom: 1px solid lightgray;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
                p{
                    color: #ffc107 !important;
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }
        .side-svg{
            background: white;
            border-radius: 28px;
            padding: 10px;
            font-size: 25px;
            cursor: pointer;
            width: 41px;
            height: 41px;
        }
        .currency{
            display: flex;
            align-items: center;
            cursor: pointer;
            @media screen and (max-width:990px){
                display: none;
            }
            p{
                color: white;
                font-size: 14px;
                font-weight: 600;
            }
            svg{
                color: white;
            }
            .dropdown-onClik{
                position: absolute;
                top: 95px;
                background: white;
                border-radius: 9px;
                padding: 18px;
                line-height: 1.8;
                width: 73px;
                text-align: center;
                p{
                    color: black;
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }
       
    }
}

.top-navbar{
    background: #232323;
    color: rgba(255, 255, 255, 0.8);
    padding: 9px;
    @media only screen and (max-width: 800px) {
        display: none;
    }
   .top-navbar-container{
    max-width: 1380px;
    margin: 0 auto;
   }
    .top-navbar-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 40px;
        .social-icons{
            width: 14%;
            display: flex;
            align-items: center;
            justify-content: end;
            svg{
                fill: rgba(255, 255, 255, 0.8);
                width: 20px;
                height: 20px;
                &::after{
                    content: "hello";
                    border: 1px solid lightgray;
                }
            }
        }
    }
    .personal-detail{
        display: flex;
        align-content: center;
        justify-content: start;
        width: 80%;
        margin: 0;
        column-gap: 24px;
        div{

            display: flex;
            align-items: center;
            margin: 0;
            p{
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }
}

.login-modal{
    .modal-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding-bottom: 20px;
        margin-bottom: 14px;
        svg{
            margin: 0;
            margin-left: 12px;
        }
        .login-tab{
            display: flex;
            align-items: center;
            column-gap: 20px;
            justify-content: center;
            .active-login-tab{
                color: #3B71FE;
                text-decoration: underline;
                cursor: pointer;
            }
            .not-active-login-tab{
                color: black;
                cursor: pointer;
            }
        }

    }

    .login-form-container{
        padding: 20px 20px 0px 20px;
        h2{
            margin-bottom: 19px;
        }
        .primary-btn{
            border-radius: 50px;
            padding: 13px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            border: none;
            width: 100%;
            margin-top: 17px;
            &:hover{
                background-color: #5582f4 ;
            }
        }
        .create-account{
            color: #3B71FE;
            text-decoration: underline;
            text-align: center;
        }
        .forgot-password{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 5px;
            p{
                margin: 0;
                text-decoration: underline;
            }
        }
        .css-dmmspl-MuiFormGroup-root{
            display: flex;
            flex-direction: row;
            margin-top: 8px;
        }
        .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
            color: grey;
        }
        .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
            width: 100%;
            .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
                margin: 0;
                margin-bottom: 16px;
                border-radius: 14px;
            }
        }
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
            padding: 12px 14px !important;
        }
        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
            top: -4px;
        }
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
            padding: 12px 14px;
        }
        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
            width: 100%;
            border-radius: 14px;
        }
    }
}
.mobile-drawer{
    display: none;
    @media only screen and (max-width: 990px) {
        display: block;
        margin: 0;
    }   
}