.user-profile-box{
    border: 1px solid lightgray;
    border-radius: 20px;
    margin-top: 25px;
    padding: 31px;
    text-align: center;
    img{
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 57px;
        margin-bottom: 10px;
    }
    .name{
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
    }
    .date{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin-top: 4px;
        margin-bottom: 0px;
        color: #5E6D77;
    }
}