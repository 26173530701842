.footer{
    background: #F7F8FA;
    .navbar-container{
        display: flex;
        justify-content: space-between;
        padding: 31px;
        margin-top: 100px;
        padding-top: 90px;
        padding-bottom: 40px;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            padding-top: 30px;
            row-gap: 17px;
        }
        .nav-links{
            width: 30%;
            @media only screen and (max-width: 800px) {
                width: 100%;
            }
            h5{
                font-size: 19px;
                padding-bottom: 20px;
            }
            ul{
                li{
                    list-style: none;
                    padding-top: 10px;
                    color: #727272;
                   
                }
            }
        }
       
    }
   
    .bottom-footer{
        border-top: 1px solid lightgray;
        padding: 21px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }
        p{
            margin: 0;
            @media only screen and (max-width: 800px) {
                width: 100%;
            }
        }
        div{
            margin: 0;
            @media only screen and (max-width: 800px) {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    .social-links{
        svg{
            margin-right: 10px;
        }
    }
}

