.rentail-detail-container{
    .guest-bar{
        padding: 0 !important;
    }
    .car-rental-card{
        margin-right: 10px;
    }
    .image-carousel{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .review-box{
        span{
            margin-left: 0 !important;
        }
        .review-no{
            margin-left: 0 !important;
        }
    }
    .property-card{
        margin: 10px;
    }
    .hotel-reviews-box{
        border-bottom: none !important;
        max-width: 1240px !important;
        margin-top: 40px;
        padding-bottom: 0 !important;
        .review-box{
            width: 21% ;
            svg{
                color: #FFB21D;
            }
        }
    }

    .tour-calender-selecter{
        h2{
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 35px; 
            margin-top: 30px;
        }
        .date-picker{
            border-bottom: 1px solid lightgray;
            padding-bottom: 40px;
            margin-bottom: 40px;
            .DayPicker__withBorder{
                border-radius: 20px;
                width: 100% !important;
            }
            .DayPicker_weekHeader_ul{
                @media only screen and (max-width: 800px) {
                    display: none;
                }
            }
            .DayPicker__withBorder > div > div{
                width: 100% !important;
            }
            .DayPicker_weekHeader_li{
                width: 49px !important;
            }
            .DayPicker_transitionContainer{
                border-radius: 20px;
                width: 100% !important;
            }
            .DayPickerKeyboardShortcuts_show__bottomRight{
                display: none;
            }
            .DayPicker_weekHeader {
                top: 71px !important;
                &:nth-child(02){
                    left: 408px !important;
                }
            }
            .CalendarMonth_caption{
                strong{
                    border-bottom: 1px solid lightgray;
                    width: 100%;
                    display: block;
                    padding-bottom: 15px;
                }
            }
            .CalendarDay__default{
                width: 53px !important;
                height: 39px !important;
                border: none;
            }
        }
    }

    .rental-map-container{
        .rental-heading{
            display: flex;
            align-items: center;
            justify-content: space-between;
            h2{
                margin: 0;
            }
            span{
                margin: 0;
                p{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
        img{
            width: 100%;
            margin-top: 30px;
            border-bottom: 1px solid lightgray !important;
            padding-bottom: 40px;
            margin-bottom: 40px;
        }
    }
    .icons-div{
        display: flex;
        align-items: center;
        grid-column-gap: 20px;
        column-gap: 30px;
        border-bottom: 1px solid lightgray;
        padding-bottom: 40px;
        margin-bottom: 40px;
        @media only screen and (max-width: 800px) {
            flex-wrap: wrap;
            column-gap: 0px !important;
            grid-column-gap: 0px;
            row-gap: 20px;
            div{
                width: 50%;
                margin: auto !important;
                h4{
                    margin: 0;
                }
            }
        }
        div{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                font-size: 14px;
                color: #5E6D77;
            }
        }
        svg{
            border: 1px solid lightgray;
            width: 51px;
            height: 51px;
            padding: 13px;
            border-radius: 10px;
            margin-right: 8px;
        }
    }
    .guest-name{
        padding-left: 20px;
        padding-right: 20px;
        background-color: #f6f9fe;
        padding-bottom: 20px;
        border-radius: 0px 0px 20px 20px;
        p{
            padding-top: 26px;
        }
        .guest-name-field{
            display: flex;
            margin-top: 15px;
            select{
                border-bottom: 1px solid lightgray !important;
                border: none;
                background: transparent;
                width: 15%;
                margin-right: 23px !important;
                margin: 0;
            }
            input{
                border-bottom: 1px solid lightgray !important;
                border: none;
                background: transparent;
                width: 51%;
                margin-right: 23px !important;
                margin: 0;
                padding: 8px;
                font-size: 16px;
            }
        }
    }
    .counter-box{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 14px;
        padding-top: 26px;
        justify-content: space-between;
        &:nth-last-child(1){
            border-bottom: none;
        }
        p{
            margin: 0;
            width: 50%;
            padding-left: 17px;
            font-weight: 500;
        }
       
        .counter{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 35%;
            margin: 0;
            padding-right: 20px;
            svg{ 
                border: 1px solid lightgray;
                border-radius: 40px;
                font-size: 25px;
                width: 30px;
                height: 30px;
                padding: 5px;
            }
        }
    }
    .information-contact{
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 30px;
        margin-top: 30px;
        h2{
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 4px;
        }
        div{
            p{
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                margin-top: 16px;
                margin-bottom: 10px;
            }
            span{
                font-weight: 400;
                font-size: 15px;
                line-height: 26px;
                margin-top: 4px;
                margin-bottom: 0px;
                color: #5E6D77;
            }
        }
    }
}

.recommended-place-rental{
   
    .recommended-places{
        background-color: white;
    }
    h2{
        text-align: start !important;
        margin-bottom: 30px !important;
    }
}