.tour-card{
    .hotels-sale{
        background-color: #e6043d;
        color: white;
        padding: 4px 8px;
        border-radius: 5px;
        position: absolute;
        top: 19px;
        left: 13px;
        pointer-events: none;
    }
    .image-carousel{
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .fav-icon{
        top: 19px !important;
    }
    .review-box{
        border-top: none !important;
        margin-bottom: 30px;
        border-bottom: 1px solid lightgray;
        padding-bottom: 28px !important;
        margin-top: 1px !important;
        margin-bottom: 20px !important;
        padding-top: 10px !important;
        .review-text{
            width: 84%;
            color: #5E6D77;
            font-size: 13px;
            margin-left: 6px;
        }
        .review-star{
            width: 13%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 800;
            
        }
    }   
  .location{
    color: #5e6d77a3 !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 22px;
    margin-bottom: 5px !important;

  }
    .price-tag{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        padding-top: 15px;
        p{
            font-size: 14px;
            padding-left: 0 !important;
            margin: 0;
        }
        .clock-time{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #5e6d77a3 !important;
            font-size: 13px !important;
            svg{
                padding-right: 5px;
            }
        }
    }
}