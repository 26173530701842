.tour-details-container{
   .hotel-detail-section{
    margin-left: auto;
    margin-right: auto;
   }
    .Included-benefit{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 14px;
        @media only screen and (max-width: 800px) {
            div{
                width: 100% !important;
            }
        }
        div{
            width: 49%;
        }
    }
    .hotel-reviews-box{
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 20px;
        .review-box{
            span{
                margin-left: 3px !important;
                margin-right: 21px !important;
            }
        }
        // max-width: 1260px !important;
    }
  
    .counter-box{
        .tour-guest-counter{
            margin: 0;
            span{
                font-size: 14px;
                color: gray;
                padding-left: 21px;
            }
        }
    }
    .hotel-facility{
        h2{
            margin-bottom: 10px !important;
        }
    }
    .tours-timing{
        flex-direction: column !important;
        align-items: start !important;
        column-gap: 10px;
        h4{
            font-size: 15px;
            margin: 0;
            padding-bottom: 4px;
        }
        p{
            margin: 0 !important;
        }
    }
    .total-price{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        p{
            font-size: 19px;
            font-weight: 800;
            margin: 0rem;
        }
    }
    .highlights-box{
        div{
            display: flex;
            align-items: center;
            p{
                margin: 0;
                padding-left: 10px;
                padding-top: 20px;
                color: #5E6D77;
                font-size: 16px;
                font-weight: 400;
            }
            .true-svg{
                margin: 0;
                background: #CEF2E5;
                color: #10AC58;
                border-radius: 30px;
                width: 20px;
                height: 20px;
                padding: 7px;
                margin-top: 16px;
            }
            .false-svg{
                margin: 0;
                background: #FAD6D6;
                color: #DA3838;
                border-radius: 30px;
                width: 25px;
                height: 24px;
                padding: 8px;
                margin-top: 16px;
            }
        }
       
    }
    .date-picker{
        position: relative;
        padding-top: 18px !important;
        p{
            position: absolute;
            top: 15px;
            z-index: 99;
            left: 25px;
            font-weight: 500;
        }
    }
    .container{
        max-width: 1260px;
        margin: 0 auto;
    }
   
    .property-card{
        margin-right: 10px;
    }
    .icons-div{
        column-gap: 75px;
        margin-top: 0;
        padding-bottom: 50px !important;
        margin-bottom: 50px !important;
        svg{
            margin-right: 11px !important;
        }
    }
    .SingleDatePickerInput__withBorder{
        border: none !important;
        .DateInput{
            width: 100% !important;
            margin-top: 10px;
            margin-left: 8px;
            padding: 0 !important;
        }
        .DateInput_input{
            font-size: 16px;
        }
    }
    .accordians-box{
        margin-top: 20px;
        .accordian-heading{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            p{
                border: 1px solid lightgray;
                padding: 5px;
                border-radius: 10px;
                margin-right: 19px;
            }
            h4{
                font-size: 17px;
            }
            svg{
                margin-right: 19px;
                font-size: 20px;
            }
        }
        .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
            box-shadow: none;
            border: 1px solid lightgray;
            margin-bottom: 10px;
            border-radius: 15px !important;
            padding: 5px;
            position: initial;
        }
       .accrodian-open-icon{
            border: 1px solid lightgray;
            border-radius: 20px;
            width: 25px;
            height: 24px;
            padding: 3px;
       }
      
       .accrodian-close-icon{
            background-color: #4b4bdf;
            border-radius: 20px;
            width: 25px;
            height: 24px;
            padding: 4px;
            color: white;
       }
        .css-ahj2mt-MuiTypography-root{
            font-weight: 600;
            font-size: 16px;
        }
    }
}