.car-trnasfer-search{
    width: 54% !important;
    .primary-btn{
        &::before{
            content: '';
            height: 0;
        }
    }
    .location-bar{
        width: 33% ;
        margin-left: 20px;
        p{
            font-weight: 600;
        }
        .inof-text{
            margin-left: 26px !important;
        }
        @media only screen and (max-width: 800px) {
            width: 100%;
        }
        &:nth-child(2){
            border-right: none;
            margin-left: 31px;
        }
        .heading{
            margin-left: -17px;
        }
       
    }
}